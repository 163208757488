import React from 'react';
import PropTypes from 'prop-types';
import Graphs from '../graphs/Graphs';
import UserInterventions from './UserInterventions';
import Spinner from '../spinner/Spinner';

class UserData extends React.Component {
  state = {
    loading: true
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({loading: false});
    }, 500);
  }

  // eslint-disable-next-line max-statements
  render() {
    const {loading} = this.state;
    // eslint-disable-next-line max-len
    const {social, voices, sleep, mood, medication, userNotifications, videoPreferences, appUsage} = this.props;

    if (loading) {
      return <Spinner/>;
    }

    return (
      <>
        <div className='grid-x summary-header position-relative'>
          <div className='medium-8 cell'>
            <h4>Participant Summary</h4>
          </div>
          <div className='medium-4 cell'>
            <div className='interventions'>
              <UserInterventions data={userNotifications}/>
            </div>
          </div>
        </div>
        <Graphs
          appUsage={appUsage}
          medication={medication}
          mood={mood}
          sleep={sleep}
          social={social}
          userNotifications={userNotifications}
          videoPreferences={videoPreferences}
          voices={voices}
        />
      </>
    );
  }
}

export default UserData;

UserData.propTypes = {
  appUsage: PropTypes.object.isRequired,
  medication: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  mood: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sleep: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  social: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userNotifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  videoPreferences: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  voices: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};